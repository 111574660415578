.file-submission-root {
  /* position: absolute;
    left: 10px;
    top: 10px; */
  fill: #FFFFFF;
  background-color: white;
  padding: 10px;
  border: 2px solid black;
  box-shadow: 10px gray;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  z-index: 100;
}

.submission-splash-background {
  position: fixed;
  left: 5px;
  right: 0px;
  top: 60px;
  bottom: 0px;
  width:550px;
  height:90%;
  background-color: #FFFFFF;
}

.submission-splash-background>.dialogue {
  position: absolute;
  left: 10px;
  /* right: 250px; */
  width: 500px;
  top: 10px;
  /* bottom: 10px; */
  background-color: #FFFFFF;
  padding: 10px 10px 50px;
  border-radius: 50px;
}

.submission-splash-background>.dialogue>.break {
  /* position: absolute; */
  margin: 20px 100px;
  right: 10px;
}

.submission-splash-background>.dialogue>.custom-file-upload {
  border: 1px solid #ccc;
  /* display: inline-block; */
  padding: 6px 12px;
  cursor: pointer;
}

.submission-splash-background>.dialogue>.multiselect-zarrs {
  height: 100px
}

.submission-splash-background>.dialogue>.in-flight {
  height: 200px;
  overflow: scroll
}

.dataset-status-view {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid black
}

.submission-step-container {
  display: flex;
  /* justify-content: space-between; */
}

.submission-step-number-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 20%;
  padding: 0px 20px 0px 20px;
}

.submission-step-number {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 6px;
  margin-top: 20px;

  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;

  font: 32px Arial, sans-serif;
}

.submission-step-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-self: flex-start
}

.persona-selector {
  text-align: left;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.persona-selector {
  color: white;
  font: 45px AvenirNext, sans-serif;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: black;
  text-shadow: 0 0 5px white; 
  vertical-align: center;
  align-items: center;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 10px;
}

.persona-selector.selected {
  border: 1px solid rgb(0, 255, 255);
  box-shadow: 1px 1px 5px rgba(0, 255, 255, 0.8);
}

.persona-selector:hover {
  opacity: 80%;
  /* background-color: rgba(0,0,0,.5); */
}