.tooltip-trigger .tooltip {
    opacity: 0;
}

.tooltip-trigger {
    text-align: left;
    cursor: pointer
}

.tooltip-trigger:hover .tooltip {
    opacity: 1;
    background-color: #EBEBEB;
    border-radius: 5px;
    cursor: pointer
}
