.stats-panel {
    position: absolute;
    right: 10px;
    top: 73px;
    bottom: 100px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 2px solid black;
    padding: 10px;
    border-radius: 10px;
    font-size: large
}

.basic-metric {
    margin-left: 10px;
    margin-right: 0;
    padding-bottom: 10px;
    padding-top: 10px;
}

.basic-metric>.metric-title {
    text-align: left;
    font-size: 1.8em;
    color: gray;
}

.basic-metric>.stats-box>.value {
    font-size: 1.4em;
    color: white;
    background-color: black;

    border: 1px solid black;
    height: auto;
    width: 75px;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 5px;
}

.basic-metric>.label {
    text-align: left;
    float: left;
    margin-left: 10;
    padding-left: 20px;
    font-size: 1em;
    white-space: pre-line;
}

.basic-metric>.stats-box>.label {
    text-align: left;
    float: left;
    width: 250px;
    margin-left: 10;
    padding-left: 20px;
    font-size: 1em;
    white-space: pre-line;
}

.basic-metric>.stats-box {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
    column-gap: 0;
}
