.map-container {
  height: 100vh;
  grid-area: map;
}

.recharts-cartesian-axis-tick {    
  font-size: 1.2rem;
  font-family: Roboto, sans-serif;
}

.bar-chart {
  position: absolute;
  right: 10px;
  top: 10px;
  fill: #FFFFFF;
  background-color: white;
  padding: 10px;
  border: 2px solid black;
  box-shadow: 10px gray;
  border-radius: 20px;
}

.mapboxgl-popup-content {
  border-radius: 35px;
  box-shadow: 0 0 0 5pt rgba(60, 132, 150, 0.3);
}

.mapboxgl-popup-content > h1 {
  font-family: 'Avenir';
  padding: -10px;
}

.title {
  position: absolute;
  right: 20px;
  bottom: 40px;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
  border: 3px solid black;
  font-size: large;
  text-align: left;
}

.layer-picker {
  position: absolute;
  left: 50px;
  top: 32px;
  /* background-color: white;
  padding: 10px;
  border-radius: 10px;
  font-size: large */
}

.App {
  text-align: center;
}

.sust-logo {
  position: absolute;
  bottom: 30px;
  right: 20px;
  width: 125px;
  height: 45px;
  filter: drop-shadow(0px 0px 5px #222);
}
