.login-display-root {
    display: flex;
    font-family:"Avenir Black";
    justify-content: space-around;
    align-items: stretch;
    background-image: url("forest-background.jpg");
}

.login-right-block {
    width: 50vw;
    height: 100vh;
    /* background-color: white; */
}

.login-left-block {
    width: 50vw;
    height: 100vh;
    background-color: rgba(255,255,255,.2);
    padding-right: 100px;
    padding-left: 60px;
}

.app-description {
    padding-top: 10vh;
    opacity: 100%;
    line-height: 200%;
}

.google-login-button {
    position: relative;
    padding: auto;
    /* border: 5px solid; */
    position: relative;
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    /* padding: 10px; */
}